import Loadeer from "loadeer";
import Swup from 'swup';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupA11yPlugin from '@swup/a11y-plugin';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import { initcookies, resetCookieConsent } from "./components/cookies";
//import { initFormAjax } from './formajax';
import { initServiceItems } from "./templates/service";
import Sketch from "./fake3d-master/js/src";
import MicroModal from 'micromodal';
import { createIcons, Download, ExternalLink, Mail, Phone, MapPin, Languages } from 'lucide';

const instance = new Loadeer();

const swup = new Swup({
    containers: ['#swup', 'nav', 'footer', '#modal', '#cc-container', '#nav-fixed'],
    plugins: [new SwupHeadPlugin({ persistAssets: true }), new SwupScriptsPlugin(), new SwupProgressPlugin(), new SwupA11yPlugin(
        { respectReducedMotion: false, }
    )]
});


swup.hooks.on('page:view', (visit) => {
    runScripts();
});

swup.hooks.on('animation:out:start', (visit) => {
    resetCookieConsent();
});


// init
function runScripts() {
    let homecontainer = document.getElementById('home-container');
    if (homecontainer)
        homecontainer.classList.remove('initial');

    unhover();

    initBackToTop('#btt');

    initLangBtn();

    startSubNavigation();

    MicroModal.init({
        onShow: modal => modalToggle(modal), // [1]
        onClose: modal => modalToggle(modal), // [2]
        openTrigger: 'data-custom-open', // [3]
        //closeTrigger: 'data-custom-close', // [4]
        openClass: 'is-open', // [5]
        //disableScroll: true, // [6]
        disableFocus: true, // [7]
        awaitOpenAnimation: true, // [8]
        awaitCloseAnimation: true, // [9]
        debugMode: true // [10]
    });

    createIcons({
        icons: {
            Download,
            ExternalLink,
            Mail,
            Phone,
            MapPin,
            Languages,
        },
    });

    let btn_cookies = document.getElementById('btn_cookies');
    initcookies(btn_cookies);

    instance.observe();

    setTimeout(() => {
        startAOS();
    }, 350);

    if (document.querySelector('.fb-form')) {
        initForms();
    }

    initServiceItems();

    // init fake 3D
    let fake3D = document.querySelector('.fake3d-frame');
    if (fake3D)
        new Sketch();
}

function modalToggle(modal) {
    document.documentElement.classList.toggle('modal');
}

// for touch devices, so last link is not in hover anymore after swup
function unhover() {
    let doc = document.documentElement;
    doc.style.pointerEvents = "none";
    setTimeout(() => { doc.style.pointerEvents = "auto"; }, 100);
}

function startSubNavigation() {

   /*  const toggles = document.querySelectorAll('.submenu-toggle');
    toggles.forEach(toggle => {
        toggle.addEventListener('click', function (e) {
            e.preventDefault();
            const parentLi = this.parentElement;
            parentLi.classList.toggle('open');
            this.textContent = parentLi.classList.contains('open') ? '▴' : '▾';
        });
    });
 */
    

    if (!isTouchDevice() || !isAndroid())
        return;
    
    /* const canHover = window.matchMedia('(hover: hover)').matches; 
    if (canHover)
        return; */

    let items = document.querySelectorAll('li.subitems > a');
    items.forEach(element => {
        element.addEventListener('click', function (ev) {
            let listItem = ev.target.parentNode;
            if (listItem.getAttribute('aria-expanded') == 'false') {
                ev.preventDefault();
                ev.stopPropagation();
                listItem.setAttribute('aria-expanded', 'true');
            }
            else {
                listItem.setAttribute('aria-expanded', 'false');
            }
        });
    });

    document.addEventListener('click', function (ev) {
        resetAriaExpanded()
    });
}

function resetAriaExpanded(){
    let items = document.querySelectorAll('[aria-expanded="true"]');
    items.forEach(element => {
        element.setAttribute('aria-expanded', 'false');
    });
}

function startAOS() {
    // Get the element to animate
    const aoselements = document.querySelectorAll('.animate-in:not(.no-animation)');

    // Define the options for the Intersection Observer
    const options = {
        root: null,
        rootMargin: '-10%',
        threshold: 0
    };

    // Create a new Intersection Observer
    const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            // If element is in viewport, add the 'show' class to trigger the animation
            if (entry.isIntersecting) {
                //entry.classList.add('show');
                entry.target.classList.add('show');
            }
            else {
                //element.classList.remove('show');
            }
        });
    }, options);

    // Start observing the element
    aoselements.forEach(aoselement => {
        observer.observe(aoselement);
    });

}

function initLangBtn() {
    const languageButton = document.getElementById('languageButton');
    const languageList = document.getElementById('languageList');

    languageButton.addEventListener('click', () => {
        toggleLanguageList()
    });

    // Toggle list visibility with Enter key when the circle is focused
    languageButton.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            toggleLanguageList();
        }
    });

    document.addEventListener('click', (event) => {
        if (!languageButton.contains(event.target) && !languageList.contains(event.target)) {
            languageList.style.display = 'none';
        }
    });

    const languages = document.querySelectorAll('.language-list li');
    languages.forEach(language => {
        language.addEventListener('click', () => {
            languageList.style.display = 'none';
        });

        // Allow selection via keyboard
        language.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                languageList.style.display = 'none';
            }
        });
    });

    function toggleLanguageList() {
        languageList.style.display = languageList.style.display === 'none' || !languageList.style.display ? 'block' : 'none';
    }
}

function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

function isAndroid() {
    //return true;
    return 'Android' == navigator.platform;
}

document.addEventListener('DOMContentLoaded', () => {
    runScripts();
});



